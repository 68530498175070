import { Auth } from "aws-amplify";
import { AUTH_TYPE } from "aws-appsync";

export const AWSConfig = {
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_region: "us-west-2",
};

export const AuthConfig = {
  master: {
    aws_cognito_identity_pool_id:
      "us-west-2:95598a24-b628-4cf1-a21d-c56aa9811170",
    Auth: {
      userPoolId: "us-west-2_fGEiQBoJq",
      userPoolWebClientId: "1igf4a3kieoia519c7mj3b4o4u",
    },
  },
  test: {
    aws_cognito_identity_pool_id:
      "us-west-2:fca1f76b-af57-47fd-9f32-1a425d0419ca",
    Auth: {
      userPoolId: "us-west-2_5HTKJvoug",
      userPoolWebClientId: "71c7q43dr0c969fl8a7p337310",
    },
  },
  develop: {
    aws_cognito_identity_pool_id:
      "us-west-2:03793d33-1de0-4885-bd8b-be0610579cd9",
    Auth: {
      userPoolId: "us-west-2_EIbGrrRHN",
      userPoolWebClientId: "7oe9sa5okmsomh5939nomt0r0k",
    },
  },
};

export const CognitoConfig = {
  master: {
    clientId: "1igf4a3kieoia519c7mj3b4o4u",
    domain: "https://admin-ui.auth.us-west-2.amazoncognito.com",
    callbackUrl: "https://sai-admin.com",
    adminGroupName: "Admin",
  },
  test: {
    clientId: "71c7q43dr0c969fl8a7p337310",
    domain: "https://admin-ui-test.auth.us-west-2.amazoncognito.com",
    callbackUrl: "https://test.sai-admin.com",
    adminGroupName: "Admin",
  },
  develop: {
    clientId: "7oe9sa5okmsomh5939nomt0r0k",
    domain: "https://admin-ui-test.auth.us-west-2.amazoncognito.com",
    callbackUrl: "https://test.sai-admin.com",
    adminGroupName: "Admin",
  },
};

export const AwsSdkConfig = {
  accessKeyId: "AKIAJ5QYD2U5HUPWS6OQ",
  secretAccessKey: "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",
  region: "us-west-2",
};

export const OrderQueueConfig = {
  bare: {
    master: {
      url: "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-jobs-new",
    },
    test: {
      url: "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-bare-test",
    },
    develop: {
      url: "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-bare-test",
    },
  },
  lm: {
    master: {
      url:
        "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-jobs-lm-prod",
    },
    test: {
      url: "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-jobs-lm",
    },
    develop: {
      url: "https://sqs.us-west-2.amazonaws.com/442298184229/ezprint-jobs-lm",
    },
  },
};

export const AppSyncConfig = {
  bare: {
    master: {
      url:
        "https://zihnok55cneonnzmiu4r4n3fmi.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),
      },
    },
    test: {
      url:
        "https://djibm3v6nbggzif44tq3rb4ibi.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),
      },
    },
    develop: {
      url:
        "https://fmkbvl63ynerfcenuawcqxks4q.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),
      },
    },
  },
  lm: {
    master: {
      url:
        "https://42ewemmq4vaepotvjkiiomhxmq.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),
      },
    },
    test: {
      url:
        "https://vu4ss34ny5ejxo65gcwqc7sr7y.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),
      },
    },
    develop: {
      url:
        "https://jcqrxs3kafc4fgrqyvqiypzcgu.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-west-2",
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: async () => await Auth.currentCredentials(),      },
    },
  },
};

export const ServerlessAPIConfig = {
  bare: {
    master: {
      url: "https://ujoqtsm3sc.execute-api.us-west-2.amazonaws.com/master",
      endpoints: {
        importCSV: "/importCSV",
      },
    },
    test: {
      url: "https://exd92m43e8.execute-api.us-west-2.amazonaws.com/test",
      endpoints: {
        importCSV: "/importCSV",
      },
    },
    develop: {
      url: "https://jb7675uqbg.execute-api.us-west-2.amazonaws.com/develop",
      endpoints: {
        importCSV: "/importCSV",
      },
    },
  },
  lm: {
    master: {
      url: "https://hw4ryes71c.execute-api.us-west-2.amazonaws.com/master",
      endpoints: {
        importCSV: "/importCSV",
      },
    },
  },
  test: {
    url: "https://acicvupcx7.execute-api.us-west-2.amazonaws.com/test",
    endpoints: {
      importCSV: "/importCSV",
    },
  },
  develop: {
    url: "https://hqueeop91b.execute-api.us-west-2.amazonaws.com/develop",
    endpoints: {
      importCSV: "/importCSV",
    },
  },
};
